import React from "react";
import quoteImage from "../../assets/images/quote-image.svg";

class QuoteCard extends React.Component {
  render() {
    return (
      <div className="quote-card">
        <img src={quoteImage} className="left-quote" alt="quote-left" />
        <img src={quoteImage} className="right-quote" alt="quote-right" />
        <p className="quote">{this.props.quote}</p>
        <label className="name">{this.props.personname}</label>
        <span className="company">{this.props.company}</span>
      </div>
    );
  }
}

export default QuoteCard;
