import React from "react";
import Layout from "../../components/App/Layout";
import Navbar from "../../components/App/Navbar";
import PageBanner from "../../components/Common/PageBanner";
import QuoteCard from "../../components/Common/QuoteCard";
import supplyChainHeroImage from "../../assets/images/financing-solutions/supply-chain-hero.jpg";
import buyers from "../../assets/images/financing-solutions/buyers.svg";
import suppliers from "../../assets/images/financing-solutions/suppliers.svg";
import financiers from "../../assets/images/financing-solutions/financiers.svg";
import reverseFactoring from "../../assets/images/financing-solutions/reverse-factoring.svg";
import platform from "../../assets/images/financing-solutions/platform.svg";
import logo from "../../assets/images/logo.svg";
import platformDoc from "../../documents/SEA-Easy-Capital-Platform.pdf";
import Footer from "../../components/App/Footer";
import whatisDoc from "../../documents/what-is-document.pdf";
import CardInfo from "../../components/Common/CardInfo";
import SEO from "../../components/App/SEO";

const SupplyChainFinancing = () => {
  return (
    <Layout>
      <Navbar />
      <div className="page">
        <PageBanner
          pageTitle="Supply Chain Financing"
          pageMainTitle="Provide early payments &amp; improve your Supply Chain"
          pageDescription="At SEA Easy Capital, we are focused on helping you provide early payments to your suppliers and improve the health of your supply chain. Our supply chain financing solution, is a financing option which improves cashflow."
          buttonText="Learn More"
          image={supplyChainHeroImage}
          scrollDestination="#supplychain-diagram"
        ></PageBanner>

        {/* supply chain section  */}
        <section className="bg-gray ptb-100" id="supplychain-diagram">
          <div className="container">
            <div className="section-title">
              <span className="sub-title">Benefits for all Stakeholders</span>
              <h2>Uniting the Supply-Chain</h2>
              <p>
                SEA Easy Capital’s technology platform brings together
                purchasers, suppliers and financiers in a unique way to provide
                tangible benefits to all
              </p>
            </div>

            <div className="supply-chain">
              <div className="supply-chain-branch supply-chain-branch--first">
                <div className="branch-left">
                  <div className="img">
                    <img src={logo} alt="logo" />
                  </div>
                </div>
                <div className="branch-right">
                  <div className="img">
                    <img src={buyers} alt="buyers" />
                  </div>
                  <div className="text">
                    <label htmlFor="buyers">Purchasers</label>
                    <ul>
                      <li>Automated uploading of approved invoices </li>
                      <li>Enable suppliers to receive immediate payment</li>
                      <li>Track supplier invoices in real-time </li>
                      <li>Automated KYC </li>
                      <li>
                        Improve cashflow and liquidity pool for strategic growth
                        initiatives
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="supply-chain-branch">
                <div className="branch-left"></div>
                <div className="branch-right">
                  <div className="img">
                    <img src={suppliers} alt="suppliers" />
                  </div>
                  <div className="text">
                    <label htmlFor="suppliers">Suppliers</label>
                    <ul>
                      <li>
                        Easy access to financing facilities with better terms
                      </li>
                      <li>Invoice payment tracking </li>
                      <li>Easy and hassle-free signup and onboarding </li>
                      <li>Better cashflow for growth and expansion</li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="supply-chain-branch supply-chain-branch--last">
                <div className="branch-left"></div>
                <div className="branch-right">
                  <div className="img">
                    <img src={financiers} alt="financiers" />
                  </div>
                  <div className="text">
                    <label htmlFor="financiers">Financiers</label>
                    <ul>
                      <li>Short - term lending opportunities </li>
                      <li>Low -risk predictable return on investment</li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* info section  */}
        <section className="what-is-factoring">
          <div className="container">
            <div className="row">
              <div className="col-lg-6 col-md-12">
                <CardInfo
                  image={reverseFactoring}
                  title="What is Supply Chain Financing?"
                  buttonText=" Learn More"
                  linkDestination={whatisDoc}
                />
              </div>
              <div className="col-lg-6 col-md-12">
                <CardInfo
                  image={platform}
                  title="How our Platform works"
                  buttonText=" Learn More"
                  linkDestination={platformDoc}
                />
              </div>
            </div>
          </div>
        </section>

        {/* quote section  */}
        {/* <section className="quote-section">
          <div className="container">
            <QuoteCard
              quote="Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. "
              personname="John Doe"
              company="ABC Corp."
            />
          </div>
        </section> */}
      </div>
      <Footer />
    </Layout>
  );
};

export default SupplyChainFinancing;
