import React from "react";
import ReactWOW from "react-wow";
import scrollTo from "gatsby-plugin-smoothscroll";

class PageBanner extends React.Component {
  render() {
    return (
      <div className="page-hero-banner">
        <section>
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-md-12">
                <div className="page-hero-banner_text">
                  <ReactWOW delay=".1s" animation="fadeInLeft">
                    <h5>{this.props.pageTitle}</h5>
                  </ReactWOW>
                  <ReactWOW delay=".1s" animation="fadeInLeft">
                    <h1>{this.props.pageMainTitle}</h1>
                  </ReactWOW>
                  <ReactWOW delay=".1s" animation="fadeInLeft">
                    <p>{this.props.pageDescription}</p>
                  </ReactWOW>
                  <ReactWOW delay=".1s" animation="fadeInLeft">
                    <button
                      className="default-btn no-icon"
                      onClick={() => scrollTo(this.props.scrollDestination)}
                    >
                      {this.props.buttonText}
                      <span />
                    </button>
                  </ReactWOW>
                </div>
              </div>
              <div className="col-lg-4 col-md-12 d-flex align-items-center">
                <div className="page-hero-banner-image">
                  <img src={this.props.image} className="img-responsive" />
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default PageBanner;
