import React from "react";

class CardInfo extends React.Component {
  render() {
    return (
      <div className="card card-info">
        <div className="img">
          <img src={this.props.image} alt="info-card-graphic" />
        </div>
        <h4>{this.props.title}</h4>
        {this.props.buttonText && (
          <a
            className="default-btn default-btn--light no-icon"
            target="_blank"
            href={this.props.linkDestination}
            rel="noreferrer"
          >
            {this.props.buttonText}
            <span />
          </a>
        )}
      </div>
    );
  }
}

export default CardInfo;
